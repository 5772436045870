<template>
<div class="statis-install-amount" id="page">
  <el-row class="sia-form-line row-start-between">
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-row>
        <el-form-item label="日期" prop="date">
          <el-date-picker value-format="YYYY-MM-DD" v-model="form.date" :disabled-date="disabledDate" type="date" :default-value="[defaultDate]" :clearable="false" />
        </el-form-item>
        <el-form-item label="省份" prop="txt_area_codes">
          <el-select v-model="form.txt_area_codes" size="small" @change="handleChecked($event)" multiple collapse-tags>
            <el-option v-for="item in regionOptions" :key="item.t_area_id" :disabled="item.disabled" :label="item.txt_area_name" :value="item.txt_code" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList()">加载数据</el-button>
        </el-form-item>
      </el-row>
    </el-form>
  </el-row>
  <el-table :data="tableData" v-loading="loading" show-summary sum-text="合计">
    <el-table-column prop="txt_area_name" label="省份" align="center" />
    <el-table-column prop="cnt_devices" sortable label="当月装机量" align="center" />
    <el-table-column prop="cnt_today_devices" sortable label="当日装机量" align="center" />
  </el-table>
</div>
</template>

<script>
// 统计分析 - 装机量
import { reactive, computed, toRefs, onMounted } from 'vue';
import { getAreas, getInstallAmount } from "api/apis.js";
import { ElMessage } from 'element-plus';
export default {
  setup() {
    const moment = require("moment");
    const state = reactive({
      form: {
        date: "",
        txt_area_code: "",
        txt_area_codes: []
      },
      tableData: [],
      loading: false,
      regionOptions: [],
      defaultDate: computed(() => moment(new Date()).format('YYYY-MM-DD'))
    });

    onMounted(() => {
      getAreaOption();
      state.form.date = state.defaultDate;
    })

    // 获取地区信息
    const getAreaOption = () => {
      getAreas({ parea_code: 0 }).then(response => {
        if (response.code == 200 && response.data) {
          state.regionOptions = response.data;
          if ((state.regionOptions || []).length > 0) {
            // state.regionOptions.unshift({ t_area_id: 0, txt_area_name: "全国", txt_code: "0" });

            state.regionOptions = state.regionOptions.map(t => {
              return {
                txt_code: t.txt_code,
                txt_area_name: t.txt_area_name,
                // disabled: false
              };
            });
            // state.form.txt_area_codes = ["0"];
            state.form.txt_area_codes = [state.regionOptions[0].txt_code];
            // handleChecked(state.form.txt_area_codes);
          }
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        getList();
      })
    };

    // 全国 选择器 事件
    const handleChecked = (event) => {
      for (let i = 0; i < state.regionOptions.length; i++) {
        let key = state.regionOptions[i];
        // 选项中包含 “全国”
        if (event.findIndex(t => t == "0") != -1) {
          if (key.txt_code != "0") {
            key.disabled = true;
          }
          // “全国” 与其他地区互斥 存在
          state.form.txt_area_codes = ["0"]
        } else {
          key.disabled = false;
        }
      }
    };

    const getList = () => {
      state.loading = true;
      getInstallAmount({
        area_code: state.form.txt_area_codes.map(t => t).join(","),
        dt_search: state.form.date
      }).then(response => {
        if (response.code == 200) {
          state.tableData = response.data;
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.loading = false;
      })
    }

    // 禁止选择的日期
    const disabledDate = (time) => {
      return time.getTime() > Date.now();
    };

    return {
      ...toRefs(state),
      getAreaOption,
      disabledDate,
      getList,
      handleChecked
    }
  }
}
</script>

<style lang="scss" scoped>
.statis-install-amount {

  .el-table {
    height: calc(100% - 100px);
      overflow: visible;
  }
}
</style>
